<template>
  <div class="oral-criterion-page">
    <van-nav-bar left-arrow fixed left-text="返回" @click-left="$router.go(-1)"></van-nav-bar>
    <div class="container">
      <my-title v-if="info.norm" :tag="info.main_name" :title="info.norm"></my-title>
      <van-empty v-else description="暂无内容" image-size="100px" />
      <div class="btn" :class="{empty: !info.norm}">
        <van-button color="#F14947" native-type="submit" @click="startAnswer">开始答题</van-button>
      </div>
    </div>
  </div>
</template>

<script>
import myTitle from "./components/title.vue";
export default {
  name: "oralCriterion",
  components: {
    myTitle
  },
  data() {
    return {
      info: {
        main_name: this.$route.query.main_name
      }
    }
  },
  created() {
    this.initData();
  },
  methods: {
    async initData() {
      let params = {
        lesson_id: this.$route.query.lesson_id,
        manor_id: this.$route.query.manor_id,
        main_id: this.$route.query.main_id,
      }
      let res = await this.$http.get('pad/oral-snag/norm', { params })
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      this.$set(this.info, 'norm', res.data.norm);
    },
    async startAnswer() {
      let params1 = {
        uid: this.$route.query.uid,
        review_id: this.$route.query.review_id,
        ass_time: this.$route.query.ass_time,
        time: this.$route.query.time,
        lesson_id: this.$route.query.lesson_id,
        manor_id: this.$route.query.manor_id,
        main_id: this.$route.query.main_id,
      }
      const res1 = await this.$http.post('kq-mailtrecor/add', params1);
      if (res1.code * 1 !== 1) {
        return this.$vantnotify(res1.msg);
      }
      let params = {
        uid: this.$route.query.uid,
        review_id: this.$route.query.review_id,
        lesson_id: this.$route.query.lesson_id,
        manor_id: this.$route.query.manor_id,
        main_id: this.$route.query.main_id,
      }
      let res = await this.$http.get('pad/oral-snag/main-enter', { params })
      if (res.code * 1 !== 1) {
        return this.$vantnotify(res.msg);
      }
      if (!res.data.info || !res.data.info.id) {
        return this.$vantnotify('获取不到当前维度评估题信息');
      }
      this.$router.replace({
        name: "oralTopic",
        query: {
          uid: this.$route.query.uid,
          review_id: this.$route.query.review_id,
          ass_time: this.$route.query.ass_time,
          time: this.$route.query.time,
          lesson_id: res.data.info.lesson_id,
          manor_id: res.data.info.manor_id,
          main_id: res.data.info.main_id,
          stage_id: res.data.info.stage_id,
          id: res.data.info.id,
          mailtrec_id: res1.data.id
        },
      })
    }
  },
  computed: {},
};
</script>

<style lang="less" scoped>
.oral-criterion-page {
  background: url(~@/assets/img/bj.png) top left/100% auto no-repeat #f8f8f8;
  height: 100vh;
  padding-top: 0.46rem;
  box-sizing: border-box;
  button {
    border: none;
  }
  .van-nav-bar {
    background: rgba(255, 255, 255, 0.3);
    &::after {
      border: none;
    }
    /deep/ .van-nav-bar__content {
      height: 0.46rem;
    }
    /deep/ .van-nav-bar__left {
      font-size: 0.14rem;
      .van-nav-bar__text {
        color: #666;
      }
      .van-icon {
        font-size: 0.14rem;
        color: #666;
        margin: 0;
      }
    }
  }
  .container {
    padding: 0.15rem 0.2rem;
    height: calc(100vh - 0.46rem);
    overflow-y: scroll;
    box-sizing: border-box;
    .btn {
      text-align: center;
      margin-top: 1rem;
      &.empty {
        margin-top: 0.05rem;
      }
    }
    button {
      height: 0.38rem;
      border-radius: 0.06rem;
      padding: 0 0.28rem;
      font-size: 0.16rem;
    }
  }
}
.van-empty {
  /deep/ .van-empty__description {
    font-size: 0.16rem;
    margin-bottom: 0;
  }
}
</style>